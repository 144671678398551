.container-homepage {

    .homepage-banner {
        text-align: center;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.82);

        h1 {
            color: #e8e6e1;
            padding: 0px;
            font-weight: 600;
            margin-top: -90px;

            @media (max-width: 575.98px) {
                font-size: 55px;
            }

            @media (min-width: 575.98px) {
                font-size: 75px;
            }
        }

        h2 {
            color: #e8e6e1;
            font-weight: 300;
            margin: auto;
            font-size: 30px;
        }

        @media only screen and (max-width: 600px) {
            #banner-logo {
                margin-top: 50px
            }
        }

        #banner-logo {
            max-height: 250px;
        }
    }

    p {
        text-align: justify;
    }

    .homepage-section {
        border-top: 1px solid rgb(0, 0, 0, 0.1);
        background-color: #242424;
    }

    .homepage-section-alt-bg {
        background-color: #131313;
    }

    .img-thumbnail {
        border: 1px solid #535353;
        padding: 0;
    }
}