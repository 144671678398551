@import url("https://fonts.googleapis.com/css2?family=Allura");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #242424 !important;
}

html,
body,
#root {
  height: 100%;
}

p,
ul {
  font-size: 14px !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #d3d6d8 !important;
  color: black !important;
}

.tagline {
  font-family: "Allura";
  font-size: 50px !important;
  font-weight: 300 !important;
}

.white-text {
  color: #dadada !important;
}

.custom-colored-link {
  color: #48BF53;
  text-decoration: none;
}

.custom-colored-link:hover {
  color: #8ae280;
}