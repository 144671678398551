// nav on the homepage with no background to it
.nav-link {
    padding: 5px 12px 5px 12px !important;
    margin-right: 2px;
    margin-left: 2px;
    border-bottom: 1px solid transparent;
    color: rgb(255, 255, 255, 0.75) !important;

    &:hover {
        border-bottom: 1px solid white;
    }
}

.nav-link.active {
    border-bottom: 1px solid white;
}